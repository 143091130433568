import {
  Card,
  Avatar,
  Space,
  Button,
  Typography,
  Message,
} from "@arco-design/web-react";
import {IconQuestionCircle, IconThumbDown} from "@arco-design/web-react/icon";
import {ReactNode, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {PersonCardProps, User} from "../models/Person";
import {routes} from "../routes/routes";
import {Meeting} from "../models/Meeting";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import axios from "axios";
import {endpoints} from "../api/endpoints";
import {buttonColor, buttonTextColor} from "../utils/cssVariables";

const {Meta} = Card;

export const PersonCard = (props: PersonCardProps) => {
  const user = props.person;

  return (
    <Card
      className="card-with-icon-hover"
      style={{
        minHeight: 450,
      }}
      cover={
        <div>
          <img
            style={{width: "100%"}}
            alt="avatar"
            src={`${endpoints.getAvatar}${user?.id}`}
          />
        </div>
      }
    >
      <div style={{textAlign: "left"}}>
        <Typography.Title heading={4} style={{wordBreak: "break-word", margin: 0, textTransform: "uppercase", fontSize: "23px"}}>{user.name}</Typography.Title>
        {/*{user.categories?.map((category) => (*/}
        {/*  <Typography.Text>{category}, </Typography.Text>*/}
        {/*))}{" "}*/}
        <Typography.Paragraph style={{marginTop: 5, wordBreak: "break-word", textTransform: "uppercase"}}>
          {user?.shortDescription}
        </Typography.Paragraph>
        <Typography.Ellipsis rows={2} showTooltip={false} expandable={false}>
          <span style={{wordBreak: "break-word"}}>{user?.fullBio}</span>
        </Typography.Ellipsis>
        <br/>
        <Space>
          <NavLink to={`${routes.MENTOR.replace(":id", user.id.toString())}`}>
            <Button
              style={{
                color: buttonTextColor,
                backgroundColor: buttonColor,
                float: "right",
              }}
              size={"large"}
              shape={"round"}>
              View Profile
            </Button>
          </NavLink>
        </Space>
      </div>
    </Card>
  );
};
