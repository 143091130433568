import {
  Form,
  Input,
  Button,
  Checkbox,
  Message,
  Upload,
  Modal,
  Typography,
  Select,
  Link,
  Divider,
} from "@arco-design/web-react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import { useEffect, useState } from "react";
import { Logo } from "../components/Logo";
import { Grid } from "@arco-design/web-react";
import axios from "axios";
import { endpoints } from "../api/endpoints";
import {
  boxBackgroundColor,
  buttonColor,
  buttonTextColor,
  colorAccent1,
} from "../utils/cssVariables";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setUser } from "../store/userSlice";

const Row = Grid.Row;
const Col = Grid.Col;
const TextArea = Input.TextArea;
const FormItem = Form.Item;

export const Register = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isMentor, setIsMentor] = useState<boolean>(false);
  const [isMentee, setIsMentee] = useState<boolean>(false);
  const [avatarId, setAvatarId] = useState<number | undefined>(undefined);
  const [categories, setCategories] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);

  useEffect(() => {
    if (!!user && user.loggedIn) {
      navigate(routes.DASHBOARD);
    }
  }, [navigate, user]);

  useEffect(() => {
    axios
      .get(endpoints.getSearchFilters)
      .then((response) => {
        setCategories(response.data?.categories || []);
        setIndustries(response.data?.industries || []);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  }, []);

  const onValuesChange = (changeValue: any, values: any) => {
    console.log("onValuesChange: ", changeValue, values);
  };

  const loginAfterRegister = () => {
    if (form) {
      form.validate().then(() => {
        axios
          .post(endpoints.login, {
            email: form.getFieldValue("email"),
            password: form.getFieldValue("password"),
          })
          .then((response) => {
            const userData = response.data;
            dispatch(
              setUser({
                loggedIn: true,
                userID: userData.userID,
                name: userData.name,
                email: userData.email,
                isMentor: userData.isMentor,
              })
            );
            navigate(routes.DASHBOARD);
            Message.success("You have successfully logged in!");
          })
          .catch((error) => {
            console.error(error);
            Message.error(
              "Oops! Something went wrong, please check your credentials and try again."
            );
          })
          .finally(() => {});
      });
    }
  };

  const onSubmit = async () => {
    if (form) {
      form.validate().then(() => {
        axios
          .post(endpoints.signup, {
            ...form.getFields(),
            isMentor: isMentor,
            avatarID: avatarId,
          })
          .then(() => {
            Message.success("Account created successfully");
            loginAfterRegister();
          })
          .catch((error) => {
            console.error(error);
            Message.error("Something went wrong");
          })
          .finally(() => {});
      });
    }
  };
  const onMentorPick = () => {
    setIsMentor((isMentor) => (isMentor = true));
  };

  const onMenteePick = () => {
    setIsMentee((isMentee) => (isMentee = true));
  };

  const onGoBack = () => {
    setIsMentor((isMentor) => (isMentor = false));
    setIsMentee((isMentee) => (isMentee = false));
  }

  return isMentor || isMentee ? (
    <div
      style={{
        justifyContent: "center",
        backgroundColor: boxBackgroundColor,
        maxWidth: 480,
        width: "calc(100% - 40px)",
        margin: "0 auto",
        padding: "20px",
        borderRadius: 8,
      }}
    >
      <Logo
        size={350}
        style={{
          marginTop: 10,
          width: "100%",
        }}
      />
      <div style={{ marginBottom: 16 }}>
        <Typography.Title heading={2}>
          Register as a {isMentee ? "Mentee" : "Mentor"}
        </Typography.Title>
        <Form
          form={form}
          autoComplete="off"
          size={"default"}
          onValuesChange={onValuesChange}
          scrollToFirstError
          onSubmit={onSubmit}
        >
          <FormItem
            layout="vertical"
            label="Full name"
            field="name"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Please enter your full name"
              autoComplete={"off"}
            />
          </FormItem>
          <FormItem
            layout="vertical"
            label="Email"
            field="email"
            rules={[{ required: true }]}
          >
            <Input
              type="email"
              placeholder="Please enter email"
              autoComplete={"off"}
            />
          </FormItem>
          <FormItem
            layout="vertical"
            label="Password"
            field="password"
            rules={[{ required: true }]}
          >
            <Input
              type="password"
              placeholder="Please enter password"
              autoComplete={"off"}
            />
          </FormItem>

          <FormItem
            layout="vertical"
            label="LinkedIn"
            field="linkedIn"
            rules={[{ required: isMentor }]}
          >
            <Input
              type="text"
              placeholder="Please enter your linkedin profile link"
            />
          </FormItem>

          <Divider orientation="left">Additional Information</Divider>

          <FormItem
            layout="vertical"
            label="Short Description"
            field="shortDescription"
            rules={[{ required: false }]}
          >
            <Input
              type="text"
              placeholder="Please enter a short description, e.g. 'Software Engineer'"
            />
          </FormItem>

          <FormItem
            layout="vertical"
            label="Bio"
            field="fullBio"
            rules={[{ required: false }]}
          >
            <TextArea
              placeholder="Please tell us about yourself"
              autoSize={{ minRows: 3, maxRows: 10 }}
            />
          </FormItem>

          <FormItem
            layout="vertical"
            label="Location"
            field="location"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input type="text" placeholder="Please enter your location" />
          </FormItem>

          <FormItem
            layout="vertical"
            label="I can advise you on"
            field="categories"
            rules={[{ required: false }]}
          >
            <Select
              placeholder="Please select your proficiency"
              mode="multiple"
              options={categories.map((category) => ({
                label: category,
                value: category,
              }))}
              allowClear
            />
          </FormItem>

          <FormItem
            layout="vertical"
            label="Industry"
            field="industries"
            rules={[{ required: false }]}
          >
            <Select
              placeholder="Please select your industries"
              mode="multiple"
              options={industries.map((industry) => ({
                label: industry,
                value: industry,
              }))}
              allowClear
            />
          </FormItem>

          {/* <FormItem
              layout="vertical"
              label="Birth day"
              field="date"
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} placeholder={"select"} />
            </FormItem> */}

          {isMentor && (
            <FormItem
              layout="vertical"
              label="Booking Link"
              field="bookingLink"
              rules={[{ required: false }]}
            >
              <Input
                type="text"
                placeholder="Your link to meetings, e.g. https://zoom.us/..."
              />
            </FormItem>
          )}

          <FormItem layout="vertical" label="Website" field="website">
            <Input type="text" placeholder="Please enter your website" />
          </FormItem>

          <Form.Item
            layout="vertical"
            label="Profile Picture"
            triggerPropName="fileList"
          >
            <Upload
              drag
              withCredentials={true}
              limit={1}
              accept="image/jpeg,image/png"
              action={endpoints.addAvatar}
              tip={"Only jpg/png files are supported"}
              onChange={(fileList, file) => {
                if (file.status === "done") {
                  const idAvatar: number = file?.response as unknown as number;
                  setAvatarId(idAvatar || undefined);
                  Message.success("Avatar uploaded successfully");
                }
              }}
            />
          </Form.Item>
          <FormItem
            layout="vertical"
            triggerPropName="checked"
            rules={[{ type: "boolean", true: true }]}
            style={{ marginBottom: 10, textAlign: "left" }}
          >
            <Checkbox>
              I agree with
              <Link
                href={"https://we-rule.com/terms-conditions"}
                target="_blank"
              >
                Terms & Conditions
              </Link>
            </Checkbox>
          </FormItem>
          <FormItem layout="vertical" style={{ marginTop: 10 }}>
            <Button
              onClick={() => {
                form.resetFields();
                onGoBack();
              }}
              size={"large"}
              shape={"round"}
            >
              Go Back
            </Button>
            <Button
              htmlType="submit"
              style={{
                marginLeft: 24,
                color: buttonTextColor,
                backgroundColor: buttonColor,
              }}
              size={"large"}
              shape={"round"}
            >
              Create Account
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  ) : (
    <div
      style={{
        justifyContent: "center",
        backgroundColor: boxBackgroundColor,
        maxWidth: 480,
        width: "calc(100% - 40px)",
        margin: "0 auto",
        padding: "20px",
        borderRadius: 8,
      }}
    >
      <Logo
        size={350}
        style={{
          marginTop: 10,
          maxWidth: "100%",
        }}
      />
      <div style={{ padding: 20, marginTop: 10 }}>
        <Typography.Text
          style={{ fontSize: 20, color: colorAccent1, wordBreak: "break-word" }}
          type="success"
          className={"alice-regular"}
        >
          To create an account, first tell us who you are.
        </Typography.Text>

        <Row justify="center" style={{ marginTop: 40, marginBottom: 20 }}>
          <Button
            style={{
              color: buttonTextColor,
              backgroundColor: buttonColor,
              height: 40,
              borderRadius: 20,
            }}
            onClick={onMentorPick}
            shape={"round"}
            size={"large"}
          >
            <b>Mentor</b>
          </Button>
          <Button
            style={{
              color: buttonTextColor,
              backgroundColor: buttonColor,
              marginLeft: 16,
              height: 40,
              borderRadius: 20,
            }}
            onClick={onMenteePick}
            shape={"round"}
            size={"large"}
          >
            <b>Mentee</b>
          </Button>
        </Row>

        <div
          style={{
            marginTop: 40,
            maxWidth: "380px",
            width: "100%",
            margin: "60px auto 0",
          }}
        >
          <Typography.Text
            style={{
              fontSize: 16,
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold",
              wordBreak: "break-word",
            }}
          >
            No one who achieved their dreams did it alone. Most greatness starts
            with great mentorship.
          </Typography.Text>
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: 60 }}>
        <Typography.Paragraph>
          Already have an account?
          <NavLink to={routes.LOGIN}>
            <Button
              style={{ padding: "0 0 0 4px" }}
              type="text"
              status="success"
            >
              <span style={{ color: colorAccent1 }}>Sign in</span>
            </Button>
          </NavLink>
        </Typography.Paragraph>
      </div>
    </div>
  );
};
