import {Image} from "@arco-design/web-react";
import bannerPlaceholder from "../assets/banner-placeholder.png";

type BannerProps = {
  size?: number;
  style?: React.CSSProperties;
};
export const Banner = (props: BannerProps) => {
  return (
    <Image
      width={props.size || "100%"}
      src={bannerPlaceholder}
      alt="banner"
      style={props.style}
    />
  );
};
