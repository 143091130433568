import {
  Avatar,
  Button,
  Calendar,
  Card, DatePicker, Link,
  Message, Select,
  Space,
  Tag,
  Typography,
} from "@arco-design/web-react";
import {PersonCardProps, User} from "../models/Person";
import {ReactNode, useEffect, useState} from "react";
import {
  IconCalendar,
  IconIdcard, IconLink,
  IconLocation,
} from "@arco-design/web-react/icon";
import {Grid} from "@arco-design/web-react";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {routes} from "../routes/routes";
import {Icon} from "@arco-design/web-react";
import axios from "axios";
import {endpoints} from "../api/endpoints";
import {getCurrentDate} from "../utils/functions";
import dayjs from "dayjs";
import {buttonColor, buttonTextColor} from "../utils/cssVariables";
import {Meeting} from "../models/Meeting";
import {MeetingCard} from "../components/MeetingCard";

const Row = Grid.Row;
const Col = Grid.Col;
const {Meta} = Card;

const show24h = !Intl.DateTimeFormat('en-US', {hour: 'numeric'}).resolvedOptions().hour12;

const fixUrl = (url: string) => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }
  return `https://${url}`;
}

export const MentorProfile = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {id} = useParams(); // Extracting ID from the URL
  const navigate = useNavigate();
  const [isBooking, setIsBooking] = useState(false);
  const [isConfirmBooking, setIsConfirmBooking] = useState(false);
  const [availableDayHours, setAvailableDayHours] = useState({} as Record<string, { date: string, time: number }[]>); // Object with available hours for each day
  const [availableDays, setAvailableDays] = useState([] as string[]); // Array of available days
  const [selectedDay, setSelectedDay] = useState(""); // Selected day
  const [selectedHour, setSelectedHour] = useState(-1); // Selected hour
  const [hours, setHours] = useState([] as number[]); // Available hours for the selected day
  const [data, setData] = useState<User>({} as User);

  const [meetings, setMeetings] = useState<Meeting[]>([]);

  const loadMeetings = () => {
    axios
      .get(`${endpoints.meetingsWithUser}${id}?timezone=${timezone}`)
      .then((response) => {
        setMeetings(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }

  useEffect(() => {
    loadMeetings();
  }, [id, isBooking]);

  const loadBooking = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios
      .get(`${endpoints.getAvailableHours}${id}?timezone=${timezone}`)
      .then((response) => {
        if (!!response.data.slots) {
          setAvailableDayHours(response.data.slots);
          setAvailableDays(Object.keys(response.data.slots));
          if (!selectedDay) {
            selectDay(getCurrentDate());
          }
        }
      })
  }

  useEffect(() => {
    loadBooking();
  }, [id, isBooking]);

  const selectDay = (day: string) => {
    const hours = availableDayHours[day];
    console.log("Selected day:", day, hours);
    if (!!hours) {
      let hoursArray = [];
      for (let i = 0; i < hours.length; i++) {
        hoursArray.push(hours[i].time);
      }
      setHours(hoursArray);
    } else {
      setHours([]);
    }
    setSelectedDay(day);
  }

  const bookMeeting = (hour: number) => {
    if (!selectedDay) {
      Message.error("Please select a day");
      return;
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    axios
      .post(`${endpoints.bookMeeting}`, {
        mentor_id: data.id,
        date: selectedDay,
        hour: hour,
        timezone: timezone,
      })
      .then((response) => {
        console.log(response.data);
        Message.success("Meeting booked successfully");
      })
      .catch((error) => {
        console.error("Error booking meeting:", error);
        Message.error("Error booking meeting");
      })
      .finally(() => {
        loadBooking();
        loadMeetings();
      });
  };

  useEffect(() => {
    axios
      .get(`${endpoints.getPublicProfile}${id}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, [id]);

  return !!data ? (
    <>
      <Card
        className="card-with-icon-hover own-card"
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
          }}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
              <img src={`${endpoints.getAvatar}${data.id}`} alt="avatar" style={{width: "100%"}}/>
            </Col>
            <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
              <Typography.Title heading={1} style={{margin: "6px 0 0 0", wordBreak: "break-word", textTransform: "uppercase"}}>{data.name}</Typography.Title>
              {!!data.shortDescription && (
                <Typography.Title heading={5} style={{marginTop: 0, wordBreak: "break-word", textTransform: "uppercase"}}>
                  {data.shortDescription}
                </Typography.Title>
              )}
              {!!data.location && (
                <Typography.Title heading={5} style={{marginTop: 0}}>
                  <IconLocation/> {data.location}
                </Typography.Title>
              )}
              <div>
                {!!data && data.linkedIn && (
                  <Link
                    href={fixUrl(data.linkedIn)}
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                    style={{display: "block"}}
                  >
                    <Typography.Title heading={5} style={{marginTop: 0}}>
                      <IconIdcard/> LinkedIn
                    </Typography.Title>
                  </Link>
                )}
                {!!data && data.website && (
                  <Link
                    href={fixUrl(data.website)}
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                    style={{display: "block"}}
                  >
                    <Typography.Title heading={5} style={{marginTop: 0}}>
                      <IconLink/> Website
                    </Typography.Title>
                  </Link>
                )}
              </div>
              <div>
                <Typography.Paragraph style={{margin: 0}}>
                  <Space wrap>
                    {data.categories?.map((c) => (
                      <Tag key={c} bordered>
                        <Typography.Title heading={6} style={{margin: 0}}>{c}</Typography.Title>
                      </Tag>
                    ))}
                  </Space>
                </Typography.Paragraph>
                <Typography.Paragraph style={{margin: 0}}>
                  <Space wrap>
                    {data.industries?.map((c) => (
                      <Tag key={c} bordered>
                        <Typography.Title heading={6} style={{margin: 0}}>{c}</Typography.Title>
                      </Tag>
                    ))}
                  </Space>
                </Typography.Paragraph>
              </div>

              {!isBooking && (
                <Button
                  style={{
                    marginTop: 6,
                    color: buttonTextColor,
                    backgroundColor: buttonColor,
                  }}
                  onClick={() => {
                    setIsBooking((isBooking) => !isBooking);
                  }}
                  icon={<IconCalendar/>}
                  size={"large"}
                  shape={"round"}
                  disabled={!availableDays || availableDays.length === 0}
                >
                  {!availableDays || availableDays.length === 0 ? "No available slots" : <>
                    {" "}Book meeting with {data.name}
                  </>}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Card>
      {isBooking && !isConfirmBooking && (
        <Card
          className="card-with-icon-hover own-card"
          style={{
            marginTop: 20,
          }}
          title="Book meeting with mentor"
        >
          {!!availableDays && availableDays.length > 0 && (
            <>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11} style={{marginBottom: 20}}>
                  <Typography.Paragraph bold>Select day</Typography.Paragraph>
                  <div style={{maxWidth: 250, width: "100%", margin: "0 auto"}}>
                    <DatePicker
                      style={{width: "100%"}}
                      disabledDate={(current) => {
                        // check in availableDays
                        return !availableDays.includes(dayjs(current).format("YYYY-MM-DD"));
                      }}
                      onChange={(date) => {
                        selectDay(dayjs(date).format("YYYY-MM-DD"));
                      }}
                    />
                  </div>
                </Col>
                {!!selectedDay && (
                  <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13} style={{marginBottom: 20}}>
                    <Typography.Paragraph bold>
                      Available hours for {selectedDay}
                    </Typography.Paragraph>
                    <div style={{maxWidth: 250, width: "100%", margin: "0 auto"}}>
                      {hours.map((hour) => (
                        <div style={{marginBottom: 10, width: "100%"}}>
                          <Button
                            key={hour}
                            onClick={() => {
                              // bookMeeting(hour);
                              setSelectedHour(hour);
                              setIsConfirmBooking(true);
                            }}
                            style={{
                              width: "100%",
                              color: buttonTextColor,
                              backgroundColor: buttonColor,
                            }}
                            size={"large"}
                            shape={"round"}
                          >
                            {show24h ? hour : hour > 12 ? hour - 12 : hour}:00 {hour >= 12 ? "PM" : "AM"}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </Col>
                )}
              </Row>

              <div>
                All hours are in your local timezone ({timezone})
              </div>
            </>
          )}
          {(!availableDays || availableDays.length === 0) && (
            <Typography.Paragraph>Sorry, this mentor has no available hours</Typography.Paragraph>
          )}
        </Card>
      )}
      {isConfirmBooking && (
        <Card
          className="card-with-icon-hover own-card"
          style={{
            marginTop: 20,
          }}
          title="Confirm booking"
        >
          <Typography.Paragraph>
            You are about to book a 1 hour meeting
            with {data.name} on {selectedDay} at {show24h ? selectedHour : selectedHour > 12 ? selectedHour - 12 : selectedHour}:00 {selectedHour >= 12 ? "PM" : "AM"}
          </Typography.Paragraph>
          <Button
            onClick={() => {
              setIsConfirmBooking(false);
            }}
            style={{marginRight: 10}}
            size={"large"}
            shape={"round"}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              bookMeeting(selectedHour);
              setIsConfirmBooking(false);
              setIsBooking(false);
            }}
            style={{
              marginLeft: 24,
              color: buttonTextColor,
              backgroundColor: buttonColor,
            }}
            size={"large"}
            shape={"round"}
          >
            Confirm booking
          </Button>

          <div style={{marginTop: 10}}>
            All hours are in your local timezone ({timezone})
          </div>
        </Card>
      )}
      {!!meetings && meetings.length > 0 && (
        <Card
          className="card-with-icon-hover own-card"
          style={{
            marginTop: 20,
          }}
          title={"Upcoming meetings with this " + (data.isMentor ? "mentor" : "person")}
        >
          <Row gutter={20}>
            {meetings.map((meeting, index) => (
              <Col key={`meeting-${index}`} xs={24} sm={24} md={24} lg={12} xl={12} style={{marginBottom: 20}}>
                <MeetingCard
                  {...meeting}
                />
              </Col>
            ))}
          </Row>
          <div>
            All hours are in your local timezone ({timezone})
          </div>
        </Card>
      )}
      {!isBooking && !!data?.fullBio && (
        <Card
          className="card-with-icon-hover own-card"
          style={{
            marginTop: 20,
            textAlign: "left",
          }}
        >
          <Typography.Title heading={4} style={{marginTop: 0, textTransform: "uppercase"}}>
            About me
          </Typography.Title>
          <Typography.Paragraph style={{whiteSpace: "pre-wrap", wordBreak: "break-word", fontSize: 18, margin: 0, padding: 0}}>
            {data?.fullBio}
          </Typography.Paragraph>
        </Card>
      )}
    </>
  ) : (
    <div>Loading data...</div>
  );
};
