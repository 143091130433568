import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "../routes/routes";
import { Suspense } from "react";
import { Login } from "./Login";
import { Register } from "./Register";
import { Search } from "./Search";
import { Dashboard } from "./DashBoard";
import { MentorProfile } from "./MentorProfile";
import { ProfileSettings } from "./Profile";
import { MentorSettings } from "./MentorSettings";

export const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={routes.LOGIN} />} />
      <Route
        path={routes.LOGIN}
        element={
          <Suspense fallback="Login">
            <Login />
          </Suspense>
        }
      />
      <Route
        path={routes.REGISTER}
        element={
          <Suspense fallback="Register">
            <Register />
          </Suspense>
        }
      />

      <Route
        path={routes.SEARCH}
        element={
          <Suspense fallback="Search">
            <Search />
          </Suspense>
        }
      />

      <Route
        path={routes.DASHBOARD}
        element={
          <Suspense fallback="Dashboard">
            <Dashboard />
          </Suspense>
        }
      />

      <Route
        path={routes.MENTOR}
        element={
          <Suspense fallback="Mentor">
            <MentorProfile />
          </Suspense>
        }
      />

      <Route
        path={routes.profile}
        element={
          <Suspense fallback="Profile">
            <ProfileSettings />
          </Suspense>
        }
      />

      <Route
        path={routes.MENTORSETTINGS}
        element={
          <Suspense fallback="Profile">
            <MentorSettings />
          </Suspense>
        }
      />
    </Routes>
  );
};
