import {Grid, Select, Message, Space, Input, Card, Typography, Pagination} from "@arco-design/web-react";
import {useCallback, useEffect, useState} from "react";
import {PersonCard} from "../components/PersonCard";
import {useNavigate} from "react-router-dom";
import {routes} from "../routes/routes";
import axios from "axios";
import {endpoints} from "../api/endpoints";
import {PersonCardProps, User} from "../models/Person";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

const Option = Select.Option;
const InputSearch = Input.Search;
const Row = Grid.Row;
const Col = Grid.Col;

export const Search = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const navigate = useNavigate();

  const [total, setTotal] = useState<number>(0);

  const [categories, setCategories] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get(endpoints.getSearchFilters)
      .then((response) => {
        setCategories(response.data?.categories || []);
        setIndustries(response.data?.industries || []);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }, []);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");

  const [profiles, setProfiles] = useState<User[]>([]);

  const getProfiles = async () => {
    console.log(searchQuery, category, industry);
    axios
      .get(`${endpoints.getSearchMentors}?&q=${searchQuery || ""}&category=${category || ""}&industry=${industry || ""}`)
      .then((response) => {
        setTotal(response.data.total);
        setProfiles(response.data.mentors);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }

  useEffect(() => {
    getProfiles();
  }, [searchQuery, category, industry]);

  return (
    <Card
      className="card-with-icon-hover own-card"
    >
      <Typography.Title heading={3} style={{textAlign: "left", margin: "10px 0 20px 0", wordBreak: "break-word", textTransform: "uppercase"}}>
        Search mentors
      </Typography.Title>
      <Row
        justify="space-between"
        style={{marginBottom: 20, marginLeft: 10, marginRight: 10}}
        gutter={20}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <InputSearch
            onChange={(e) => setSearchQuery(e)}
            placeholder="Search"
            onPressEnter={() => getProfiles()}
            style={{marginBottom: 10}}
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Select placeholder="Category" allowClear onChange={(value) => setCategory(value)} showSearch={true}
                  style={{marginBottom: 10}}>
            {categories.map((option) => (
              <Option key={`Category-${option}`} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Select placeholder="Industry" allowClear onChange={(value) => setIndustry(value)} showSearch={true}>
            {industries.map((option) => (
              <Option key={`Industry-${option}`} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>


      <Row
        gutter={20}
      >
        {profiles?.map((profile, index) => (
          <Col
            key={profile.id}
            span={24}
            xl={{span: 8}}
            xs={{span: 24}}
            md={{span: 12}}
            style={{marginBottom: 20}}
          >
            <PersonCard
              person={profile}
            />
          </Col>
        ))}
      </Row>

      {/*<Pagination total={total} pageSize={10} onChange={(page) => console.log(page)}/>*/}
    </Card>
  );
};
