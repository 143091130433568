import {
  Form,
  Input,
  Button,
  Checkbox,
  Message,
  Upload,
  Modal,
  Typography,
  Select, Card,
} from "@arco-design/web-react";
import {NavLink, useNavigate} from "react-router-dom";
import {routes} from "../routes/routes";
import {useEffect, useState} from "react";
import {Grid} from "@arco-design/web-react";
import axios from "axios";
import {endpoints} from "../api/endpoints";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import {buttonColor, buttonTextColor} from "../utils/cssVariables";

const Row = Grid.Row;
const Col = Grid.Col;
const TextArea = Input.TextArea;
const FormItem = Form.Item;

export const ProfileSettings = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [privateProfile, setPrivateProfile] = useState<any>(null);

  useEffect(() => {
    axios
      .get(endpoints.currentProfile)
      .then((response) => {
        setPrivateProfile(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }, []);

  const [categories, setCategories] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get(endpoints.getSearchFilters)
      .then((response) => {
        setCategories(response.data?.categories || []);
        setIndustries(response.data?.industries || []);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }, []);

  const onSubmit = async () => {
    if (form) {
      form.validate().then(() => {
        axios
          .post(endpoints.updateProfile, privateProfile)
          .then(() => {
            navigate(routes.MENTOR.replace(":id", privateProfile.id));
            Message.success("Profile updated successfully");
          })
          .catch((error) => {
            console.error(error);
            Message.error("Something went wrong");
          })
          .finally(() => {
          });
      });
    }
  };

  return (
    <Card
      style={{
        width: "100%",
      }}
      className="card-with-icon-hover own-card"
    >
      <Row style={{margin: 10, marginTop: 0}} justify="center">
        <Col span={24} xl={{span: 24}} xs={{span: 24}} md={{span: 18}}>
          <Typography.Title heading={3} style={{textAlign: "left", margin: "10px 0 20px 0", wordBreak: "break-word", textTransform: "uppercase"}}>
            Edit Profile
          </Typography.Title>

          {(!!privateProfile && !!privateProfile?.email) && (
            <>
              <Form
                form={form}
                autoComplete="off"
                size={"default"}
                scrollToFirstError
              >
                <FormItem
                  layout="vertical"
                  label="Full name"
                  field="name"
                >
                  <Input
                    placeholder="Please enter your full name"
                    autoComplete={"off"}
                    defaultValue={privateProfile?.name}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        name: value,
                      });
                    }}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Short Description"
                  field="shortDescription"
                  rules={[{required: false}]}
                >
                  <Input
                    placeholder="Please tell us about yourself"
                    defaultValue={privateProfile?.shortDescription}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        shortDescription: value,
                      });
                    }}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Bio"
                  field="fullBio"
                  rules={[{required: false}]}
                >
                  <TextArea
                    placeholder="Please tell us about yourself"
                    defaultValue={privateProfile?.fullBio}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        fullBio: value,
                      });
                    }}
                    autoSize={{minRows: 3, maxRows: 10}}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Location"
                  field="location"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    placeholder="please enter your location"
                    defaultValue={privateProfile?.location}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        location: value,
                      });
                    }}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="I can advise you on"
                  field="categories"
                  rules={[{required: false}]}
                >
                  <Select
                    placeholder="please select your proficiency"
                    mode="multiple"
                    options={categories.map((category) => ({
                      label: category,
                      value: category,
                    }))}
                    allowClear
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        categories: value,
                      });
                    }}
                    defaultValue={privateProfile?.categories}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Industry"
                  field="industries"
                  rules={[{required: false}]}
                >
                  <Select
                    placeholder="Please select your industries"
                    mode="multiple"
                    options={industries.map((industry) => ({
                      label: industry,
                      value: industry,
                    }))}
                    allowClear
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        industries: value,
                      });
                    }}
                    defaultValue={privateProfile?.industries}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Booking Link"
                  field="bookingLink"
                  rules={[{required: false}]}
                >
                  <Input
                    placeholder="please enter your booking link"
                    defaultValue={privateProfile?.bookingLink}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        bookingLink: value,
                      });
                    }}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Linkedin"
                  field="linkedIn"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    placeholder="please enter your linkedin profile link"
                    defaultValue={privateProfile?.linkedIn}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        linkedIn: value,
                      });
                    }}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <FormItem
                  layout="vertical"
                  label="Website"
                  field="website"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    placeholder="please enter your website"
                    defaultValue={privateProfile?.website}
                    onChange={(value) => {
                      setPrivateProfile({
                        ...privateProfile,
                        website: value,
                      });
                    }}
                  />
                  <div
                    style={{display: "none"}}> {/* TODO: why the forms are not updated without this with default values */}
                    {privateProfile?.email}
                  </div>
                </FormItem>

                <Form.Item
                  layout="vertical"
                  label="Profile Picture"
                  triggerPropName="fileList"
                >
                  <Upload
                    drag
                    withCredentials={true}
                    limit={1}
                    accept="image/jpeg,image/png"
                    action={endpoints.addAvatar}
                    tip={"Only jpg/png files are supported"}
                    onChange={(fileList, file) => {
                      if (file.status === "done") {
                        const idAvatar: number =
                          file?.response as unknown as number;
                        setPrivateProfile({
                          ...privateProfile,
                          avatarID: idAvatar,
                        });
                        Message.success("Avatar uploaded successfully");
                      }
                    }}
                  />
                </Form.Item>

                <FormItem layout="vertical">
                  <Button
                    htmlType="submit"
                    style={{
                      color: buttonTextColor,
                      backgroundColor: buttonColor,
                    }}
                    onClick={onSubmit}
                  >
                    Update Profile
                  </Button>
                </FormItem>

              </Form>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
};
