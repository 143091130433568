import {useNavigate} from "react-router-dom";
import {routes} from "../routes/routes";
import {Grid, Select, Input, Card, Typography} from "@arco-design/web-react";
import {Button} from "@arco-design/web-react";
import {useEffect, useState} from "react";
import {PersonCard} from "../components/PersonCard";
import axios from "axios";
import {endpoints} from "../api/endpoints";
import {Meeting} from "../models/Meeting";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import {MeetingCard} from "../components/MeetingCard";
import {User} from "../models/Person";
import {buttonColor, buttonTextColor} from "../utils/cssVariables";
import { Banner } from "../components/Banner";

const Option = Select.Option;
const InputSearch = Input.Search;
const Row = Grid.Row;
const Col = Grid.Col;
export const Dashboard = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const user = useSelector((state: RootState) => state.user.user);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [profiles, setProfiles] = useState<User[]>([]);
  const navigate = useNavigate();

  const getMeetings = async () => {
    axios
      .get(`${endpoints.meetings}?timezone=${timezone}`)
      .then((response) => {
        setMeetings(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    getMeetings();
  }, []);

  const getProfiles = async () => {
    axios
      .get(`${endpoints.getRandomMentors}`)
      .then((response) => {
        setProfiles(response.data.mentors);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }

  useEffect(() => {
    getProfiles();
  }, []);

  return (
    <div>
      <Card
        className="card-with-icon-hover own-card"
      >
        <Banner />
      </Card>
      <Card
        className="card-with-icon-hover own-card"
        style={{marginTop: 20}}
      >
        <Typography.Title heading={3} style={{textAlign: "left", margin: "0 0 20px 0", wordBreak: "break-word", textTransform: "uppercase"}}>
          Upcoming meetings
        </Typography.Title>

        {meetings.length > 0 && (
          <>
            <Row style={{marginTop: 20}} gutter={20}>
              {meetings.map((meeting, index) => (
                <Col key={`meeting-${index}`} xs={24} sm={12} md={12} lg={12} xl={12} style={{marginBottom: 20}}>
                  <MeetingCard
                    {...meeting}
                  />
                </Col>
              ))}
            </Row>
            <div>
              All hours are in your local timezone ({timezone})
            </div>
          </>
        )}
        {meetings.length === 0 && (
          <Typography.Text style={{textAlign: "left", width: "100%"}}>
            No upcoming meetings
            <br/>
            <Button
              onClick={() => navigate(routes.SEARCH)}
              style={{
                marginTop: 10,
                color: buttonTextColor,
                backgroundColor: buttonColor,
              }}
              size={"large"}
              shape={"round"}
            >
              Search Mentors and book a meeting
            </Button>
          </Typography.Text>
        )}
      </Card>

      <Card
        className="card-with-icon-hover own-card"
        style={{marginTop: 20}}
      >
        <Button
          onClick={() => navigate(routes.SEARCH)}
          style={{
            color: buttonTextColor,
            backgroundColor: buttonColor,
            float: "right",
          }}
          size={"large"}
          shape={"round"}
        >
          Search Mentors
        </Button>
        <Typography.Title heading={3} style={{textAlign: "left", margin: "0 0 20px 0", wordBreak: "break-word", textTransform: "uppercase"}}>
          Featured Mentors
        </Typography.Title>

        <Row
          style={{marginBottom: 20, marginLeft: 10, marginRight: 10}}
          gutter={20}
        >
          {profiles?.map((profile, index) => (
            <Col
              key={`meeting-${index}`}
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{marginBottom: 20}}
            >
              <PersonCard
                person={profile}
              />
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};
