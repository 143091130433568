import React, {useState} from "react";
import {
  Button,
  Menu,
  MenuProps,
  Grid,
  Avatar,
  Message, Link,
} from "@arco-design/web-react";
import {
  IconHome,
  IconCalendar,
  IconSettings,
  IconUser,
  IconPlusCircle,
  IconPoweroff, IconMenu, IconSearch,
} from "@arco-design/web-react/icon";
import {JSX} from "react/jsx-runtime";
import {Logo} from "../components/Logo";
import {NavLink, useLocation} from "react-router-dom";
import {routes} from "../routes/routes";
import {headerColor, headerTextColor} from "../utils/cssVariables";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import axios from "axios";
import {endpoints} from "../api/endpoints";

const MenuItem = Menu.Item;
const Row = Grid.Row;
const Col = Grid.Col;

export const BaseMenu = (
  props: JSX.IntrinsicAttributes & MenuProps & React.RefAttributes<unknown>
) => {
  const location = useLocation();

  const [collapse, setCollapse] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);

  console.log("user baseMenu", user);

  const logOut = () => {
    axios
      .post(endpoints.logout)
      .then((response) => {
        Message.success("Logged out successfully");
        setTimeout(() => {
          window.location.reload();
        }, 10);
      })
      .catch((error) => {
        console.error(error);
        Message.error("Could not log out");
      });
  };

  const menuItems = <>
    <NavLink to={routes.DASHBOARD}>
      <MenuItem key={routes.DASHBOARD} style={{backgroundColor: headerColor, color: headerTextColor}}>
        <IconHome style={{color: headerTextColor}}/>
        Upcoming Meetings
      </MenuItem>
    </NavLink>
    <NavLink to={routes.SEARCH}>
      <MenuItem key={routes.SEARCH} style={{backgroundColor: headerColor, color: headerTextColor}}>
        <IconSearch style={{color: headerTextColor}}/>
        Find Mentors
      </MenuItem>
    </NavLink>

    {user?.isMentor && (
      <NavLink to={routes.MENTORSETTINGS}>
        <MenuItem key={routes.MENTORSETTINGS} style={{backgroundColor: headerColor, color: headerTextColor}}>
          <IconCalendar style={{color: headerTextColor}}/>
          Set your availability
        </MenuItem>
      </NavLink>
    )}

    {user?.isMentor && (
      <NavLink to={routes.MENTOR.replace(":id", user?.userID.toString())}>
        <MenuItem
          key={routes.MENTOR.replace(":id", user?.userID.toString())}
          style={{backgroundColor: headerColor, color: headerTextColor}}>
          <IconUser style={{color: headerTextColor}}/>
          Your Profile
        </MenuItem>
      </NavLink>
    )}

    <NavLink to={routes.profile}>
      <MenuItem key={routes.profile} style={{backgroundColor: headerColor, color: headerTextColor}}>
        <IconSettings style={{color: headerTextColor}}/>
        Edit your Profile
      </MenuItem>
    </NavLink>

    <NavLink to={routes.LOGIN} onClick={logOut}>
      <MenuItem key={routes.LOGIN} style={{backgroundColor: headerColor, color: headerTextColor}}>
        <IconPoweroff style={{color: headerTextColor}}/>
        Log out
      </MenuItem>
    </NavLink>
  </>

  return (
    <div style={{width: "100%"}} className={"own-header"}>
      <Row style={{paddingTop: 5, paddingBottom: 5}} justify="end">
        <Col style={{paddingTop: 2}} flex="auto">
          <NavLink to={routes.DASHBOARD} className={"own-header-logo"}>
            <Logo size={133} style={{marginLeft: 10}}/>
          </NavLink>
        </Col>
        <Col flex="none" className={"display-only-on-desktop"} style={{maxWidth: "100%"}}>
          <Menu
            style={{
              backgroundColor: headerColor,
              marginRight: 25,
            }}
            collapse={false}
            mode="horizontal"
            selectedKeys={[location.pathname]}
          >
            {menuItems}
          </Menu>
        </Col>
        <Col flex="auto" style={{textAlign: "right"}} className={"display-only-on-mobile"}>
          {/*{user?.userID && (*/}
          {/*  <Avatar size={40} onClick={() => setCollapse(!collapse)}>*/}
          {/*    <img src={`${endpoints.getAvatar}${user?.userID || 0}`} alt="avatar" style={{width: "100%"}}/>*/}
          {/*  </Avatar>*/}
          {/*)}*/}
          <IconMenu
            onClick={() => setCollapse(!collapse)}
            style={{cursor: "pointer", float: "right", fontSize: 35, marginLeft: 10, marginRight: 10, marginTop: 3}}
          />
          {collapse && (
            <Menu
              style={{
                position: "absolute",
                zIndex: 999,
                backgroundColor: headerColor,
                top: "43px",
                right: "5px",
                textAlign: "left",
                width: "100vw",
              }}
              collapse={false}
              mode="vertical"
              onClick={() => {
                setTimeout(() => {
                  setCollapse(false)
                }, 80);
              }}
            >
              {menuItems}
            </Menu>
          )}
        </Col>
      </Row>
    </div>
  );
};
