import {Capacitor} from "@capacitor/core";

export let HOST = '';
if (window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1')) {
  HOST = 'http://localhost:8080';
}
if (Capacitor.isNativePlatform()) {
  HOST = 'https://demoapp.we-rule.com';
}

export const endpoints = {
  whoami: `${HOST}/api/v1/whoami`,
  login: `${HOST}/api/v1/login`,
  signup: `${HOST}/api/v1/user/signup`,
  meetings: `${HOST}/api/v1/meetings/list`,
  meetingsWithUser: `${HOST}/api/v1/meetings/list/`,
  updateProfile: `${HOST}/api/v1/profile/update`,
  currentProfile: `${HOST}/api/v1/profile/current`,
  getProfile: `${HOST}/api/v1/profile/public/`,
  addSlot: `${HOST}/api/v1/meetings/slot`,
  getSlots: `${HOST}/api/v1/meetings/slots`,
  deleteSlot: `${HOST}/api/v1/meetings/slot/`,
  getAvailableHours: `${HOST}/api/v1/meetings/slots/available/`,
  getPublicProfile: `${HOST}/api/v1/profile/public/`,
  getSearchMentors: `${HOST}/api/v1/search/mentors?limit=30`,
  getRandomMentors: `${HOST}/api/v1/search/random?limit=3`,
  getSearchFilters: `${HOST}/api/v1/search/filters`,
  bookMeeting: `${HOST}/api/v1/meetings/book`,
  cancelMeeting: `${HOST}/api/v1/meetings/cancel`,
  logout: `${HOST}/api/v1/logout`,
  getAvatar: `${HOST}/api/v1/user/avatar/`,
  addAvatar: `${HOST}/api/v1/user/upload-avatar`,
};
