export const routes = {
  LOGIN: "/login",
  REGISTER: "/register",

  DASHBOARD: "/dashboard",
  SEARCH: "/search",

  MENTOR: "/mentor/:id",
  MENTORSETTINGS: "/mentor-settings",

  profile: "/profile",

  SETTINGS: "/settings",

  NOTFOUND: "/404",
};
