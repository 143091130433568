import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { AppLayout } from "./Layout/Layout";
import { Pages } from "./pages/Pages";
import { ConfigProvider, Message } from "@arco-design/web-react";
import enUS from "@arco-design/web-react/es/locale/en-US";
import { useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { store } from "./store/store";
import axios from "axios";
import { endpoints } from "./api/endpoints";
import { setUser } from "./store/userSlice";

const WhoAmIWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(endpoints.whoami)
      .then((response) => {
        dispatch(
          setUser({
            ...response.data,
          })
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});
  });

  return <>{children}</>;
};

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  // Detects if device is in standalone mode (already using PWA)
  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  // Checks if should display install popup notification:
  if (isIos() && !isInStandaloneMode()) {
    // Message.info(
    //   "To install app on your Iphone: tap shae button and then press 'Add to Home Screen'."
    // );
  }

  useEffect(() => {
    // Listen for the 'beforeinstallprompt' event
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile
      // event.preventDefault(); // commented to show it nativly
      // Save the event so it can be triggered later
      setDeferredPrompt(event);
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        // Clear the deferredPrompt state variable
        setDeferredPrompt(null);
      });
    }
  };

  axios.interceptors.request.use(
    function (config) {
      config.withCredentials = true;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <>
      {/*{!!deferredPrompt && (*/}
      {/* TODO: please replace this with a proper message popup */}
      {/*  <button onClick={handleInstallClick}>Install App</button>*/}
      {/*)}*/}
      <Provider store={store}>
        <WhoAmIWrapper>
          <Router>
            <ConfigProvider locale={enUS}>
              <AppLayout>
                <Pages />
              </AppLayout>
            </ConfigProvider>
          </Router>
        </WhoAmIWrapper>
      </Provider>
    </>
  );
}

export default App;
